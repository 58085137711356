import "./styles.css";
import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/de";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { purple } from "@mui/material/colors";

const supabase = createClient(
  "https://qubrnrejeepefytiwdlw.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF1YnJucmVqZWVwZWZ5dGl3ZGx3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTYzNDk1NjMsImV4cCI6MjAxMTkyNTU2M30.ry24w8r6I0uMyZanOuICvZwEyPl5dB1bi7e3E8F1-i0"
);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options_mood = {
  scales: {
    y: {
      min: 0,
      max: 10,
      stepSize: 2
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top"
    },
    title: {
      display: false,
      text: "Aktuelle Stimmung"
    }
  }
};

export const options_sleep = {
  scales: {
    y: {
      min: 0,
      max: 13,
      stepSize: 2
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top"
    },
    title: {
      display: false,
      text: "Aktuelle Stimmung"
    }
  }
};

const labels = Array.from({ length: 31 }, (_, i) => i).map((x) => x + 1);

export default function Content() {
  const [moods, setMoods] = useState([]);
  const [fullMonth, setFullMonth] = useState([]);
  const [value, setValue] = useState(dayjs().hour(12));

  const mood_levels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [mood_level, setMood_level] = useState(5);

  const [hours_of_sleep, set_hours_of_sleep] = useState(8);

  useEffect(() => {
    getMoods();
  }, [value]);

  async function getMoods() {
    const { data } = await supabase
      .from("Moods")
      .select()
      .order("date", { ascending: true });
    setMoods(data);
    setFullMonth(fillDays(value.format("YYYY"), value.format("MM"), data));

    console.log("updated");
  }

  async function save_to_db(date, mood_level, hours_of_sleep) {
    var error = null;

    if (moods.filter((mood) => mood.date == date).length == 0) {
      error = await supabase.from("Moods").insert({
        date: date,
        hours_of_sleep: hours_of_sleep,
        mood_level: mood_level
      });
    } else {
      error = await supabase
        .from("Moods")
        .update({
          date: date,
          hours_of_sleep: hours_of_sleep,
          mood_level: mood_level
        })
        .eq("date", date);
    }
    if (error.error) {
      console.log(error.error);
      return;
    }
    await getMoods();
  }

  const fillDays = function (year, month, moods) {
    const daysInMonth = [];
    for (let i = 1; i <= dayjs(`${year}-${month}`).daysInMonth(); i++) {
      const current_day = dayjs(`${year}-${month}-${i}`).format("YYYY-MM-DD");
      const { mood_level, hours_of_sleep } = moods.find(
        (mood) => mood.date == current_day
      ) || {
        mood_level: 0,
        hours_of_sleep: 0
      };
      daysInMonth.push({
        date: current_day,
        level: mood_level,
        hours_of_sleep
      });
    }
    return daysInMonth;
  };

  const data_mood = {
    labels /*: Array.from({ length: value.daysInMonth() }, (_, i) => i).map(
      (x) => x + 1
    ),*/,
    datasets: [
      {
        label: "Stimmung",
        data: fullMonth.map((day) => day.level),
        backgroundColor: "rgba(255, 99, 132, 0.5)"
      }
    ]
  };
  const data_sleep = {
    labels /*: Array.from({ length: value.daysInMonth() }, (_, i) => i).map(
      (x) => x + 1
    ),*/,
    datasets: [
      {
        label: "Schlaf in Stunden",
        data: fullMonth.map((day) => day.hours_of_sleep),
        backgroundColor: "rgba(70, 99, 132, 0.5)"
      }
    ]
  };

  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#3f51b5"
      },
      secondary: {
        main: "#f50057"
      }
    }
  });

  return moods ? (
    <ThemeProvider theme={theme}>
      <div>
        <Stack spacing={2} direction="column">
          <Box sx={{ flexGrow: 2 }}>
            <AppBar position="sticky">
              <Toolbar>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1 }}
                ></Typography>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  🌞 Stimmungstagebuch 🌦️
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Typography>{value.format("MMMM YYYY")}</Typography>
          </Stack>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Button
              variant="outlined"
              onClick={() => setValue(value.subtract(1, "month"))}
            >
              Vorheriger Monat
            </Button>
            <Button
              variant="outlined"
              onClick={() => setValue(value.add(1, "month"))}
            >
              Nächster Monat
            </Button>
          </Stack>

          {fullMonth ? (
            <div>
              <Bar options={options_mood} data={data_mood} />
              <Bar options={options_sleep} data={data_sleep} />
            </div>
          ) : null}
          <Divider orientation="horizontal" flexItem />
        </Stack>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
          <Stack spacing={3} direction="column">
            <Box sx={{ flexGrow: 2 }}>
              <AppBar position="sticky">
                <Toolbar>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                  ></Typography>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Neuer Eintrag
                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
            <Typography variant="h6">Stimmung</Typography>
            <ToggleButtonGroup
              value={mood_level}
              exclusive
              fullWidth
              onChange={(event, newMood_level) => {
                if (newMood_level != null) {
                  setMood_level(newMood_level);
                }
              }}
            >
              {mood_levels.map((mood_levelItem) => (
                <ToggleButton key={mood_levelItem} value={mood_levelItem}>
                  {mood_levelItem}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <Typography variant="h6">Schlaf in Stunden</Typography>

            <Slider
              aria-label="Hours slept"
              defaultValue={30}
              getAriaValueText={(val) => `${val} + hours`}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={14}
              value={hours_of_sleep}
              onChange={(e, hours) => set_hours_of_sleep(hours)}
            />
            <Typography variant="h6">Datum</Typography>

            <DateCalendar
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
            />
            <Button
              variant="outlined"
              onClick={async () =>
                save_to_db(
                  value.format("YYYY-MM-DD"),
                  mood_level,
                  hours_of_sleep
                )
              }
            >
              Eintragen
            </Button>
          </Stack>
        </LocalizationProvider>
      </div>
    </ThemeProvider>
  ) : (
    <h1>No data</h1>
  );
}
